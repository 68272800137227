<template>
  <div class="container d-flex flex-fill justify-content-center align-items-center">
    <div class="p-2">
      <!-- INIT CONTENT -->

      <!-- INIT DEFAULT VIEW -->
      <div class="col d-flex justify-content-center box-shadow ">
        <div class="card p-4" style="min-width: 30vw; max-width:500px;">
          <div class="card-body p-2">
            <!-- Authform & RecoverForm -->
            <transition name="component-fade" mode="out-in">
              <!-- OJO:: El parent de <component> es <transition> -->
              <component
                v-bind:is="view"
              ></component>
            </transition>
            <!-- Authform & RecoverForm -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthForm from "./components/AuthForm";

export default {
  data() {
    return {
      view: "auth-form"
    };
  },
  components: {
    "auth-form": AuthForm
  },  
  methods: {},
};
</script>

<style lang="css">
  @import "~@/assets/css/main.css";
</style>