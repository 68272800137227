//Importamos la instancia axios del fichero request.js
import http from "@/utils/request";

//Clase para enviar peticiones al servidor relacionadas con los dispositivos
class AuthService {
    /* Identificarse en el sistema por email/password */
    login (data) {
        return http.post("auth/login", data);
    } 
}

//Exportamos la instancia de la clase
export default new AuthService();