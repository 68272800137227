<template>
  <form
    :class="formValidate ? 'was-validated' : 'needs-validation'"
    novalidate
    @submit="subLogin"
  >
    <h1 class="text-center tituloForm">Sistema de Identificación</h1>
    <hr class="mb-4" />

    <div class="alert alert-danger" role="alert" :class="showError == true ? 'd-block' : 'd-none'">
      Los datos introducidos no corresponden con ningún usuario.
    </div>

    <Input
      type="test"
      v-model="user"
      id="user"
      minlength="1"
      label="* Usuario"
      invalid="Introduzca un usuario válido"
      icon="fas fa-at"
      :required="true"
    />

    <Input
      type="password"
      v-model="password"
      id="password"
      minlength="6"
      label="* Introduzca su clave de acceso"
      invalid="La contraseña debe tener al menos 6 caracteres"
      icon="fas fa-key"
      :required="true"
    >
      <span class="input-group-text revealPassword" @click="revealPassword">
        <i class="fas fa-eye" id="show_eye"></i>
        <i class="fas fa-eye-slash d-none" id="hide_eye"></i>
      </span>
    </Input>

    <div class="col d-flex justify-content-center">
      <button
        class="
          col-12 col-sm-12 col-md-10 col-lg-12 col-xl-6
          btn btn-lg btn-primary
          btnSubmit
          mt-4
        "
        type="submit"
      >
        Acceder
      </button>
    </div>
  </form>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Input from "@/components/commons/Input";
import AuthService from "@/services/AuthService";

export default {
  data() {
    return {
      user: "",
      password: "",
      formValidate: false,
      showError: false,
    };
  },
  components: {
    Input,
  },
  computed: {
    ...mapState("login", ["v_auth"]),
  },
  methods: {
    ...mapActions("login", ["v_setAuth"]),
    /**
     * @type: {event}
     * @description: Función para solicitar identificación de usuario a la API
     * @info Hacemos uso de await para esperar a que nos llegue la info del usuario
     *       antes de seguir. Es importante
     */
    subLogin(e) {
      // Prevenir accion por defecto del boton submit
      e.preventDefault();
      this.formValidate = true;

      // Si se han rellenado los campos del formulario
      if (this.password.length > 5 && this.user.length > 0) {
        // Estructura de datos
        let dataStruct = {
          user: this.user,
          password: this.password,
        };

        //Disparamos la accion para hacer login en la sesion
        AuthService.login(dataStruct)
          .then((response) => {
            if (response.data) {
              if (response.data.auth == true) {
                this.$router.push({ name: "wizard-step-0" });
                this.v_setAuth(true)
              } else {
                this.formValidate = false;
                this.v_setAuth(false)
                this.showError = true
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    revealPassword() {
      var x = document.getElementById("password");
      var show_eye = document.getElementById("show_eye");
      var hide_eye = document.getElementById("hide_eye");
      hide_eye.classList.remove("d-none");
      if (x.type === "password") {
        x.type = "text";
        show_eye.style.display = "none";
        hide_eye.style.display = "block";
      } else {
        x.type = "password";
        show_eye.style.display = "block";
        hide_eye.style.display = "none";
      }
    },
  },
};
</script>



<style scoped>
.options {
  font-size: 0.8rem;
  color: #333333;
  margin: 0vh auto 0vh auto;
  cursor: pointer;
  font-weight: 400;
}

.revealPassword {
  cursor: pointer;
}
</style>